import * as React from 'react';

const SvgBrag = props => (
  <svg
    height={61.759}
    viewBox="0 0 244.65 61.759"
    width={244.65}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <linearGradient
        gradientUnits="objectBoundingBox"
        id="Brag_svg__a"
        x2={1}
        y1={0.5}
        y2={0.5}
      >
        <stop
          offset={0.06}
          stopColor="#fff"
        />
        <stop
          offset={0.134}
          stopColor="#fff"
          stopOpacity={0.961}
        />
        <stop
          offset={0.259}
          stopColor="#fff"
          stopOpacity={0.855}
        />
        <stop
          offset={0.421}
          stopColor="#fff"
          stopOpacity={0.682}
        />
        <stop
          offset={0.614}
          stopColor="#fff"
          stopOpacity={0.447}
        />
        <stop
          offset={0.829}
          stopColor="#fff"
          stopOpacity={0.145}
        />
        <stop
          offset={0.927}
          stopColor="#fff"
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        gradientUnits="objectBoundingBox"
        id="Brag_svg__b"
        x2={1}
        y1={0.5}
        y2={0.5}
      >
        <stop
          offset={0.06}
          stopColor="#fff"
          stopOpacity={0}
        />
        <stop
          offset={0.166}
          stopColor="#fff"
          stopOpacity={0.145}
        />
        <stop
          offset={0.4}
          stopColor="#fff"
          stopOpacity={0.447}
        />
        <stop
          offset={0.608}
          stopColor="#fff"
          stopOpacity={0.682}
        />
        <stop
          offset={0.784}
          stopColor="#fff"
          stopOpacity={0.855}
        />
        <stop
          offset={0.92}
          stopColor="#fff"
          stopOpacity={0.961}
        />
        <stop
          offset={1}
          stopColor="#fff"
        />
      </linearGradient>
    </defs>
    <g data-name="Group 1203">
      <path
        d="M120.572 47.2V9.28l-9.612 8.353V8.352L120.572 0h8.617v47.2Z"
        data-name="Path 1511"
        fill="#fff"
      />
      <path
        d="M20.431 34.842v-8.46l-2.772 5.5h-1.917l-2.793-5.5v8.46h-2.833V20.328h2.793l3.791 7.848 3.772-7.848h2.792v14.514Z"
        data-name="Path 1512"
        fill="#fff"
      />
      <path
        d="m34.904 34.842-.856-2.568H28.89l-.877 2.568h-2.956l5.28-14.514h2.222l5.3 14.514Zm-3.385-10.233-1.834 5.279h3.608Z"
        data-name="Path 1513"
        fill="#fff"
      />
      <path
        d="m47.359 34.842-2.834-5.789h-2.039v5.789h-2.833V20.328h5.688a4.388 4.388 0 0 1 4.709 4.444 3.906 3.906 0 0 1-2.651 3.811l3.241 6.259Zm-2.2-11.986h-2.673v3.832h2.671a1.921 1.921 0 1 0 0-3.832Z"
        data-name="Path 1514"
        fill="#fff"
      />
      <path
        d="m61.424 34.842-3.731-6.6-1.875 2.238v4.362h-2.834V20.328h2.834v6.319l5.117-6.319h3.445L59.572 26.1l5.157 8.745Z"
        data-name="Path 1515"
        fill="#fff"
      />
      <path
        d="m74.796 34.842-.856-2.568h-5.157l-.877 2.568H64.95l5.28-14.514h2.222l5.3 14.514Zm-3.384-10.233-1.835 5.279h3.609Z"
        data-name="Path 1516"
        fill="#fff"
      />
      <path
        d="m95.016 34.842-5.749-8.908v8.908h-2.833V20.328h2.528l5.749 8.887v-8.887h2.833v14.514Z"
        data-name="Path 1517"
        fill="#fff"
      />
      <path
        d="m108.634 34.842-2.834-5.789h-2.038v5.789h-2.834V20.328h5.687a4.388 4.388 0 0 1 4.71 4.444 3.906 3.906 0 0 1-2.651 3.811l3.242 6.259Zm-2.2-11.986h-2.67v3.832h2.67a1.921 1.921 0 1 0 0-3.832Z"
        data-name="Path 1518"
        fill="#fff"
      />
      <path
        d="M110.569 44.248H7.272A7.251 7.251 0 0 1 .028 37V18.194a7.252 7.252 0 0 1 7.244-7.245h103.3v1.631H7.272a5.62 5.62 0 0 0-5.613 5.614V37a5.619 5.619 0 0 0 5.613 5.613h103.3Z"
        data-name="Path 1519"
        fill="url(#Brag_svg__a)"
        transform="translate(-.028)"
      />
      <path
        d="M151.608 34.842h-2.365l-2.67-8.806-2.671 8.806h-2.365l-3.873-14.514h2.956l2.243 9.153 2.65-9.153h2.12l2.65 9.153 2.263-9.153h2.956Z"
        data-name="Path 1520"
        fill="#fff"
      />
      <path
        d="M167.672 29.379h-2.793v5.463h-2.834V20.328h5.627a4.533 4.533 0 1 1 0 9.051Zm-.143-6.523h-2.65v3.975h2.65a1.991 1.991 0 1 0 0-3.975Z"
        data-name="Path 1521"
        fill="#fff"
      />
      <path
        d="M183.633 33.374a5.638 5.638 0 0 1-7.849 0c-1.468-1.467-1.427-3.282-1.427-5.79s-.041-4.321 1.427-5.789a5.641 5.641 0 0 1 7.849 0c1.467 1.468 1.447 3.283 1.447 5.789s.02 4.323-1.447 5.79Zm-2.1-9.866a2.359 2.359 0 0 0-1.815-.775 2.4 2.4 0 0 0-1.835.775c-.551.612-.693 1.284-.693 4.076s.142 3.466.693 4.079a2.406 2.406 0 0 0 1.835.774 2.361 2.361 0 0 0 1.815-.774c.549-.613.712-1.286.712-4.079s-.163-3.464-.712-4.076Z"
        data-name="Path 1522"
        fill="#fff"
      />
      <path
        d="M187.972 34.842V20.328h2.833v11.987h6.585v2.527Z"
        data-name="Path 1523"
        fill="#fff"
      />
      <path
        d="M203.69 34.964a6.814 6.814 0 0 1-5.3-1.916l1.834-1.835a4.752 4.752 0 0 0 3.507 1.224c1.672 0 2.569-.632 2.569-1.794a1.619 1.619 0 0 0-.449-1.224 2.166 2.166 0 0 0-1.284-.509l-1.754-.245a4.916 4.916 0 0 1-2.812-1.2 3.828 3.828 0 0 1-1.04-2.853c0-2.589 1.916-4.4 5.055-4.4a6.221 6.221 0 0 1 4.689 1.691l-1.794 1.773a4.04 4.04 0 0 0-2.976-1c-1.508 0-2.243.836-2.243 1.834a1.4 1.4 0 0 0 .429 1.04 2.5 2.5 0 0 0 1.345.572l1.713.244a4.8 4.8 0 0 1 2.772 1.121 4.067 4.067 0 0 1 1.121 3.078c0 2.809-2.345 4.399-5.382 4.399Z"
        data-name="Path 1524"
        fill="#fff"
      />
      <path
        d="M216.595 34.964a5.321 5.321 0 0 1-3.915-1.59 4.2 4.2 0 0 1-1.182-2.181 19.831 19.831 0 0 1-.244-3.609 19.813 19.813 0 0 1 .244-3.607 4.13 4.13 0 0 1 1.182-2.182 5.32 5.32 0 0 1 3.915-1.589 5.112 5.112 0 0 1 5.34 4.586h-2.874a2.391 2.391 0 0 0-2.446-2.059 2.239 2.239 0 0 0-1.814.755c-.591.632-.714 1.284-.714 4.1s.123 3.466.714 4.1a2.239 2.239 0 0 0 1.814.755 2.391 2.391 0 0 0 2.446-2.06h2.874a5.127 5.127 0 0 1-5.34 4.581Z"
        data-name="Path 1525"
        fill="#fff"
      />
      <path
        d="M224.381 34.842V20.328h9.56v2.528h-6.727v3.4h5.728v2.528h-5.728v3.527h6.727v2.527Z"
        data-name="Path 1526"
        fill="#fff"
      />
      <path
        d="M97.713 33.3h-.4v-1.633h.4a5.619 5.619 0 0 0 5.613-5.612V7.245a5.62 5.62 0 0 0-5.613-5.614H0V0h97.713a7.253 7.253 0 0 1 7.244 7.245v18.81a7.252 7.252 0 0 1-7.244 7.245Zm-59.405 0H0v-1.633h38.308V33.3Z"
        data-name="Subtraction 2"
        fill="url(#Brag_svg__b)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit={10}
        transform="translate(139.192 10.949)"
      />
      <path
        d="M5.788 59.844h-.63l-1.4-5.054-1.4 5.054h-.63L.114 53.532h.718l1.232 5.089 1.392-5.089h.6l1.392 5.089 1.232-5.089h.718Z"
        data-name="Path 1528"
        fill="#fff"
      />
      <path
        d="m14.61 59.844-1.924-3.36-1.33 1.6v1.764h-.674v-6.316h.674v3.626l2.944-3.626h.824l-1.986 2.438 2.27 3.874Z"
        data-name="Path 1529"
        fill="#fff"
      />
      <path
        d="m20.2 59.844-.506-1.428h-2.757l-.505 1.428h-.718l2.322-6.312h.568l2.314 6.312ZM18.329 54.5l-1.188 3.334h2.358Z"
        data-name="Path 1530"
        fill="#fff"
      />
      <path
        d="M23.352 54.135v5.709h-.674v-5.709h-1.826v-.6h4.326v.6Z"
        data-name="Path 1531"
        fill="#fff"
      />
      <path
        d="M26.442 59.844v-6.312h3.919v.6h-3.245v2.228h2.766v.6h-2.766v2.279h3.245v.6Z"
        data-name="Path 1532"
        fill="#fff"
      />
      <path
        d="M35.498 59.17a2.314 2.314 0 0 1-3.3.072c-.594-.6-.594-1.215-.594-2.554s0-1.959.594-2.553a2.232 2.232 0 0 1 1.613-.656 2.152 2.152 0 0 1 2.208 1.889h-.674a1.552 1.552 0 0 0-2.624-.834c-.4.408-.443.834-.443 2.154s.044 1.756.443 2.163a1.515 1.515 0 0 0 1.09.443 1.534 1.534 0 0 0 1.2-.541 1.65 1.65 0 0 0 .355-1.143v-.479h-1.552v-.593h2.225v1a2.18 2.18 0 0 1-.541 1.632Z"
        data-name="Path 1533"
        fill="#fff"
      />
      <path
        d="M41.272 59.242a2.315 2.315 0 0 1-3.227 0c-.594-.6-.594-1.215-.594-2.554s0-1.959.594-2.553a2.312 2.312 0 0 1 3.227 0c.594.594.594 1.214.594 2.553s.006 1.959-.594 2.554Zm-.523-4.708a1.54 1.54 0 0 0-2.18 0c-.4.408-.444.834-.444 2.154s.045 1.747.444 2.154a1.54 1.54 0 0 0 2.18 0c.4-.407.444-.833.444-2.154s-.043-1.746-.442-2.154Z"
        data-name="Path 1534"
        fill="#fff"
      />
      <path
        d="m47.165 59.844-1.436-2.8h-1.516v2.8h-.674v-6.312h2.421a1.707 1.707 0 0 1 1.87 1.756 1.638 1.638 0 0 1-1.374 1.693l1.5 2.863Zm-1.267-5.709h-1.685v2.314h1.685a1.161 1.161 0 1 0 0-2.314Z"
        data-name="Path 1535"
        fill="#fff"
      />
      <path
        d="M49.439 59.844v-6.312h.674v6.312Z"
        data-name="Path 1536"
        fill="#fff"
      />
      <path
        d="M52.026 59.844v-6.312h.674v6.312Z"
        data-name="Path 1537"
        fill="#fff"
      />
      <path
        d="M59.097 57.247h-1.676v2.6h-.674v-6.315h2.35a1.86 1.86 0 1 1 0 3.715Zm-.062-3.112h-1.614v2.509h1.614a1.257 1.257 0 1 0 0-2.509Z"
        data-name="Path 1538"
        fill="#fff"
      />
      <path
        d="m66.043 59.844-1.436-2.8h-1.516v2.8h-.674v-6.312h2.42a1.708 1.708 0 0 1 1.871 1.756 1.639 1.639 0 0 1-1.374 1.693l1.5 2.863Zm-1.271-5.709h-1.681v2.314h1.681a1.161 1.161 0 1 0 0-2.314Z"
        data-name="Path 1539"
        fill="#fff"
      />
      <path
        d="M68.317 59.844v-6.312h3.918v.6h-3.244v2.228h2.766v.6h-2.766v2.279h3.244v.6Z"
        data-name="Path 1540"
        fill="#fff"
      />
      <path
        d="M76.072 57.247h-1.678v2.6h-.673v-6.315h2.351a1.86 1.86 0 1 1 0 3.715Zm-.062-3.112h-1.616v2.509h1.614a1.257 1.257 0 1 0 0-2.509Z"
        data-name="Path 1541"
        fill="#fff"
      />
      <path
        d="m82.635 59.844-.505-1.428h-2.758l-.5 1.428h-.719l2.319-6.312h.567l2.314 6.312ZM80.764 54.5l-1.192 3.332h2.359Z"
        data-name="Path 1542"
        fill="#fff"
      />
      <path
        d="m88.083 59.844-1.436-2.8h-1.516v2.8h-.674v-6.312h2.415a1.708 1.708 0 0 1 1.871 1.756 1.639 1.639 0 0 1-1.371 1.693l1.5 2.863Zm-1.268-5.709h-1.684v2.314h1.684a1.161 1.161 0 1 0 0-2.314Z"
        data-name="Path 1543"
        fill="#fff"
      />
      <path
        d="m94.036 59.844-.505-1.428h-2.759l-.5 1.428h-.718l2.318-6.312h.567l2.314 6.312ZM92.165 54.5l-1.193 3.332h2.359Z"
        data-name="Path 1544"
        fill="#fff"
      />
      <path
        d="M97.18 54.135v5.709h-.674v-5.709H94.68v-.6h4.326v.6Z"
        data-name="Path 1545"
        fill="#fff"
      />
      <path
        d="M103.682 59.242a2.315 2.315 0 0 1-3.227 0c-.593-.6-.593-1.215-.593-2.554s0-1.959.593-2.553a2.312 2.312 0 0 1 3.227 0c.6.594.6 1.214.6 2.553s-.005 1.959-.6 2.554Zm-.522-4.708a1.541 1.541 0 0 0-2.181 0c-.4.408-.444.834-.444 2.154s.045 1.747.444 2.154a1.541 1.541 0 0 0 2.181 0c.4-.407.443-.833.443-2.154s-.045-1.746-.443-2.154Zm-.94-1.614h-.585l.78-1.356h.762Z"
        data-name="Path 1546"
        fill="#fff"
      />
      <path
        d="M110.762 59.844h-.63l-1.4-5.054-1.4 5.054h-.63l-1.613-6.312h.718l1.232 5.089 1.392-5.089h.6l1.392 5.089 1.232-5.089h.718Z"
        data-name="Path 1547"
        fill="#fff"
      />
      <path
        d="M115.24 59.844v-.638l3.156-5.071h-3.032v-.6h3.795v.565l-3.187 5.142h3.183v.6Z"
        data-name="Path 1548"
        fill="#fff"
      />
      <path
        d="M124.243 59.242a2.315 2.315 0 0 1-3.227 0c-.593-.6-.593-1.215-.593-2.554s0-1.959.593-2.553a2.312 2.312 0 0 1 3.227 0c.595.594.595 1.214.595 2.553s0 1.959-.595 2.554Zm-.522-4.708a1.541 1.541 0 0 0-2.181 0c-.4.408-.443.834-.443 2.154s.044 1.747.443 2.154a1.541 1.541 0 0 0 2.181 0c.4-.407.443-.833.443-2.154s-.045-1.746-.443-2.154Z"
        data-name="Path 1549"
        fill="#fff"
      />
      <path
        d="M128.964 59.844h-2.455v-6.312h2.376a1.666 1.666 0 0 1 1.861 1.684 1.446 1.446 0 0 1-.931 1.383 1.528 1.528 0 0 1 1.029 1.5 1.672 1.672 0 0 1-1.88 1.745Zm-.133-5.709h-1.649v2.19h1.649c.7 0 1.242-.346 1.242-1.1s-.541-1.09-1.242-1.09Zm.071 2.792h-1.72v2.315h1.72a1.162 1.162 0 1 0 0-2.315Z"
        data-name="Path 1550"
        fill="#fff"
      />
      <path
        d="M136.097 59.242a2.316 2.316 0 0 1-3.228 0c-.593-.6-.593-1.215-.593-2.554s0-1.959.593-2.553a2.314 2.314 0 0 1 3.228 0c.594.594.594 1.214.594 2.553s0 1.959-.594 2.554Zm-.523-4.708a1.541 1.541 0 0 0-2.181 0c-.4.408-.443.834-.443 2.154s.044 1.747.443 2.154a1.541 1.541 0 0 0 2.181 0c.4-.407.443-.833.443-2.154s-.045-1.746-.445-2.154Z"
        data-name="Path 1551"
        fill="#fff"
      />
      <path
        d="M138.761 59.9a1.9 1.9 0 0 1-1.445-.567l.452-.453a1.226 1.226 0 0 0 .993.417c.824 0 1.294-.522 1.294-1.454v-4.311h.674v4.4a1.856 1.856 0 0 1-1.968 1.968Z"
        data-name="Path 1552"
        fill="#fff"
      />
      <path
        d="M142.552 59.844v-6.312h3.92v.6h-3.245v2.228h2.767v.6h-2.767v2.279h3.245v.6Zm3.4 1.915h-.7l.629-1.321h.594Z"
        data-name="Path 1553"
        fill="#fff"
      />
      <path
        d="M149.808 54.135v5.709h-.673v-5.709h-1.827v-.6h4.327v.6Z"
        data-name="Path 1554"
        fill="#fff"
      />
      <path
        d="m156.923 59.844-3.351-5.062v5.062h-.674v-6.312h.638l3.352 5.045v-5.045h.673v6.312Z"
        data-name="Path 1555"
        fill="#fff"
      />
      <path
        d="M159.472 59.844v-6.312h.673v6.312Z"
        data-name="Path 1556"
        fill="#fff"
      />
      <path
        d="m165.738 59.844-.506-1.428h-2.76l-.5 1.428h-.718l2.323-6.312h.567l2.314 6.312Zm-1.866-5.344-1.188 3.334h2.358Z"
        data-name="Path 1557"
        fill="#fff"
      />
      <path
        d="M168.393 59.9a1.9 1.9 0 0 1-1.445-.567l.452-.453a1.227 1.227 0 0 0 .993.417c.825 0 1.294-.522 1.294-1.454v-4.311h.674v4.4a1.856 1.856 0 0 1-1.968 1.968Z"
        data-name="Path 1558"
        fill="#fff"
      />
      <path
        d="m175.772 59.844-.505-1.428h-2.755l-.5 1.428h-.719l2.323-6.312h.568l2.314 6.312Zm-1.87-5.346-1.188 3.334h2.358Zm1.941 7.261h-.7l.629-1.321h.594Z"
        data-name="Path 1559"
        fill="#fff"
      />
      <path
        d="M179.472 59.9a2.257 2.257 0 0 1-1.614-.655c-.594-.6-.594-1.215-.594-2.554s0-1.959.594-2.553a2.249 2.249 0 0 1 1.614-.656 2.127 2.127 0 0 1 2.189 1.879h-.689a1.49 1.49 0 0 0-1.5-1.276 1.519 1.519 0 0 0-1.09.443c-.4.408-.444.843-.444 2.163s.045 1.756.444 2.163a1.546 1.546 0 0 0 2.606-.833h.673a2.127 2.127 0 0 1-2.189 1.879Z"
        data-name="Path 1560"
        fill="#fff"
      />
      <path
        d="M184.862 57.238v2.606h-.674v-2.606l-1.88-3.706h.727l1.49 3.023 1.472-3.023h.726Z"
        data-name="Path 1561"
        fill="#fff"
      />
      <path
        d="M189.672 59.9a2.258 2.258 0 0 1-1.614-.655c-.593-.6-.593-1.215-.593-2.554s0-1.959.593-2.553a2.251 2.251 0 0 1 1.614-.656 2.13 2.13 0 0 1 2.19 1.879h-.69a1.491 1.491 0 0 0-1.5-1.276 1.521 1.521 0 0 0-1.09.443c-.4.408-.444.843-.444 2.163s.044 1.756.444 2.163a1.546 1.546 0 0 0 2.606-.833h.674a2.129 2.129 0 0 1-2.19 1.879Z"
        data-name="Path 1562"
        fill="#fff"
      />
      <path
        d="M197.105 59.844v-2.881h-3v2.881h-.674v-6.312h.674v2.828h3v-2.828h.674v6.312Z"
        data-name="Path 1563"
        fill="#fff"
      />
      <path
        d="m205.841 59.844-3.352-5.062v5.062h-.674v-6.312h.639l3.351 5.045v-5.045h.673v6.312Z"
        data-name="Path 1564"
        fill="#fff"
      />
      <path
        d="m212.072 59.844-.505-1.428h-2.757l-.5 1.428h-.718l2.322-6.312h.568l2.314 6.312Zm-1.87-5.346-1.187 3.334h2.357Z"
        data-name="Path 1565"
        fill="#fff"
      />
      <path
        d="M215.608 59.844v-.638l3.156-5.071h-3.031v-.6h3.794v.565l-3.182 5.143h3.182v.6Z"
        data-name="Path 1566"
        fill="#fff"
      />
      <path
        d="M224.682 59.17a2.314 2.314 0 0 1-3.3.072c-.594-.6-.594-1.215-.594-2.554s0-1.959.594-2.553a2.232 2.232 0 0 1 1.613-.656 2.152 2.152 0 0 1 2.208 1.889h-.674a1.552 1.552 0 0 0-2.624-.834c-.4.408-.443.834-.443 2.154s.044 1.756.443 2.163a1.517 1.517 0 0 0 1.09.443 1.535 1.535 0 0 0 1.2-.541 1.65 1.65 0 0 0 .355-1.143v-.479h-1.552v-.593h2.225v1a2.176 2.176 0 0 1-.541 1.632Z"
        data-name="Path 1567"
        fill="#fff"
      />
      <path
        d="m230.467 59.844-.5-1.428h-2.757l-.506 1.428h-.718l2.323-6.312h.567l2.314 6.312Zm-1.87-5.346-1.188 3.334h2.357Z"
        data-name="Path 1568"
        fill="#fff"
      />
      <path
        d="M235.853 59.17a2.315 2.315 0 0 1-3.3.072c-.593-.6-.593-1.215-.593-2.554s0-1.959.593-2.553a2.236 2.236 0 0 1 1.614-.656 2.152 2.152 0 0 1 2.208 1.889h-.674a1.552 1.552 0 0 0-2.624-.834c-.4.408-.443.834-.443 2.154s.044 1.756.443 2.163a1.517 1.517 0 0 0 1.09.443 1.535 1.535 0 0 0 1.2-.541 1.65 1.65 0 0 0 .355-1.143v-.479h-1.55v-.593h2.225v1a2.176 2.176 0 0 1-.544 1.632Z"
        data-name="Path 1569"
        fill="#fff"
      />
      <path
        d="M238.048 59.844v-6.312h3.919v.6h-3.245v2.228h2.765v.6h-2.765v2.279h3.245v.6Zm3.395 1.915h-.7l.629-1.321h.6Z"
        data-name="Path 1570"
        fill="#fff"
      />
      <path
        d="M243.78 56.894v-3.26l-.686.6v-.423l.686-.593h.371v3.672Z"
        data-name="Path 1571"
        fill="#fff"
      />
      <text
        data-name="W aptekach"
        fill="#fff"
        fontFamily="Barlow-Regular, Barlow"
        fontSize={9}
        transform="translate(208 46.879)"
      >
        <tspan
          x={-26.753}
          y={0}
        >
          W APTEKACH
        </tspan>
      </text>
    </g>
  </svg>
);

export default SvgBrag;
