import * as React from 'react';

const SvgManufacturerLogo = props => (
  <svg
    data-name="Component 4 \u2013 1"
    height={34.016}
    width={127.067}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="ManufacturerLogo_svg__a">
        <path
          d="M0 0h127.067v34.016H0z"
          data-name="Rectangle 168"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      clipPath="url(#ManufacturerLogo_svg__a)"
      data-name="Group 1092"
      fill="#fff"
    >
      <path
        d="M77.152 0h1.712a27.24 27.24 0 0 1 9.722 2.048v7.718c-.188.048-.376-.09-.542-.165a22.352 22.352 0 0 0-10.476-2.047c-4.134.2-7.182 1.715-8.514 4.885-1.4 3.339-.949 8.553.878 10.935 1.968 2.566 5.591 3.377 10.225 3.046.1-1.545.012-3.269.041-4.883h-5.13v-6.969h13.518v18.155a56.114 56.114 0 0 1-9.64 1.293h-2.587c-5.475-.325-9.7-1.838-12.729-4.716a16.3 16.3 0 0 1-4.759-12.69C59.289 5.392 66 .553 77.152 0"
        data-name="Path 1783"
      />
      <path
        d="M105.364 8.849c-2.756 1.462-4.543 4.638-4.258 8.849.348 5.155 3.557 8.78 9.139 8.471a7.925 7.925 0 0 0 5.8-2.963 9.85 9.85 0 0 0 1.92-6.8 9.376 9.376 0 0 0-2.545-6.262 8.239 8.239 0 0 0-6.551-2.252 8.882 8.882 0 0 0-3.508.959M108.872 0h1.373c10.164.7 15.97 6.008 16.822 16.068v1.88c-.843 9.9-6.5 15.225-16.445 16.068H108.5c-.01-.034-.035-.051-.084-.043A17.11 17.11 0 0 1 96.6 29.049a16.752 16.752 0 0 1-4.55-12.228 18.545 18.545 0 0 1 1.377-7.011c2.5-5.965 7.6-9.344 15.445-9.81"
        data-name="Path 1784"
      />
      <path
        d="M15.341 34.016h-2.214c-.006-.034-.035-.049-.083-.042C5.628 33.586 1.4 30.011.272 23.374a34.276 34.276 0 0 1-.248-5.593c0-5.824-.037-11.868.042-17.529C2.9.279 5.853.2 8.621.295v15.814c0 2.77-.175 5.5.626 7.306.819 1.842 2.389 2.934 5.009 2.922 2.575-.01 4.165-1.086 4.967-2.922.784-1.8.583-4.634.583-7.345 0-5.266-.035-10.7.041-15.818 2.853.027 5.816-.052 8.6.043v17.527a33.692 33.692 0 0 1-.248 5.635c-1.163 6.643-5.44 10.129-12.855 10.559"
        data-name="Path 1785"
      />
      <path
        d="M40.007 7.348v7.8c2.34-.1 4.7.323 6.133-.543s2.029-3.84 1.087-5.676c-1.055-2.045-4.063-1.744-7.179-1.71-.049.007-.047.066-.041.127M32.034.252c4.305.135 8.7-.2 12.561.042 3.9.246 6.936 1.5 8.935 3.672a11.383 11.383 0 0 1 2.336 9.766 9.426 9.426 0 0 1-5.466 7.136c1.369 1.431 2.444 4.13 4.175 5.385a4.581 4.581 0 0 0 3.84.416v6.386c-2.274.958-5.724.373-7.6-.458-2.635-1.17-4.195-3.505-5.55-6.053-.789-1.48-1.45-3.476-3.005-4.132a7 7 0 0 0-2.212-.168c-.1 3.69-.015 7.556-.041 11.313h-8.014V.377c-.007-.061-.008-.118.041-.125"
        data-name="Path 1786"
      />
    </g>
  </svg>
);

export default SvgManufacturerLogo;

