import React from 'react';

export default function MyComponent(props) {
  return (
    <svg
      height="37.789"
      id="LOGO"
      viewBox="0 0 240 37.789"
      width={240}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.214,10.429a5.214,5.214,0,1,1,5.207-5.214A5.22,5.22,0,0,1,5.214,10.429Zm0-9.25A4.037,4.037,0,1,0,9.245,5.214,4.042,4.042,0,0,0,5.211,1.179Z"
        data-name="Subtraction 1"
        fill="#fff"
        id="Subtraction_1"
        transform="translate(229.579 1.908)"
      />
      <g
        data-name="Group 4"
        id="Group_4"
      >
        <path
          d="M226.341,95.753a1.373,1.373,0,0,0-.722-1v-.016a1.428,1.428,0,0,0,.952-1.313,1.414,1.414,0,0,0-.492-1.132,2.842,2.842,0,0,0-1.74-.41,10.327,10.327,0,0,0-1.608.115v5.268h1.2v-2.1h.361c.492,0,.722.2.87.853a6.149,6.149,0,0,0,.377,1.247h1.231C226.669,97.066,226.5,96.361,226.341,95.753ZM224.4,94.293h-.476V92.815a2.685,2.685,0,0,1,.542-.049c.558.016.9.263.9.755C225.372,94,225.011,94.293,224.4,94.293Z"
          data-name="Path 16"
          fill="#fff"
          id="Path_16"
          transform="translate(10.231 -87.629)"
        />
        <path
          d="M95.067,89.29,80.69,118.357h10l2.659-6.762,6.762-.016.164,6.778h10.045L107.623,89.29Zm.607,16.659,4.087-10.077.23,10.077Z"
          data-name="Path 17"
          fill="#fff"
          id="Path_17"
          transform="translate(-80.69 -89.29)"
        />
        <path
          d="M105.943,89.323l-6.184,29.013h9.512l6.182-29.013Z"
          data-name="Path 18"
          fill="#fff"
          id="Path_18"
          transform="translate(-68.462 -89.269)"
        />
        <path
          d="M125.67,94.483l-3.291,15.473a8.54,8.54,0,0,1-2.447.479c-1,0-1.525-.725-1.525-2.085a16.6,16.6,0,0,1,.4-2.923l2.326-10.944h-9.508l-3.051,14.268a4.906,4.906,0,0,0-.161,1.282c0,3.569,3.893,5.451,10.194,5.451a55.241,55.241,0,0,0,12.479-1.644l4.093-19.356Z"
          data-name="Path 19"
          fill="#fff"
          id="Path_19"
          transform="translate(-62.947 -85.96)"
        />
        <path
          d="M125.892,104.415a16.78,16.78,0,0,0-.394,3.3c0,4.891,2.807,7.5,7.008,7.5a16.9,16.9,0,0,0,6.664-1.362l-.2.919c-.64,2.971-3.168,4.53-7.468,4.53a12.924,12.924,0,0,1-6.581-1.756l-1.034,4.94c1.083.558,4.973,1.477,10.307,1.477,9.913,0,13.13-3.808,14.246-8.83l4.1-19.219a36.781,36.781,0,0,0-11.325-1.723C132.277,94.19,127.386,97.686,125.892,104.415Zm14.164,5.268a6.2,6.2,0,0,1-2.692.64c-1.362,0-2.035-.919-2.035-2.856a15.25,15.25,0,0,1,1.953-7.008,2.957,2.957,0,0,1,2.462-1.379,5.962,5.962,0,0,1,2.478.492Z"
          data-name="Path 20"
          fill="#fff"
          id="Path_20"
          transform="translate(-53.043 -86.174)"
        />
        <path
          d="M157.123,94.19c-8.863,0-13.639,3.721-15.018,10.213a14.668,14.668,0,0,0-.394,3.492c0,5.328,3.611,7.77,10.52,7.77,6.138,0,11.029-1.033,12.671-1.721l3.857-18.065A42.529,42.529,0,0,0,157.123,94.19Zm-.968,16.114a8.363,8.363,0,0,1-2.724.475c-1.329,0-1.937-.967-1.937-2.836,0-3.541,1.608-8.869,4.464-8.869a5.393,5.393,0,0,1,2.445.639Z"
          data-name="Path 21"
          fill="#fff"
          id="Path_21"
          transform="translate(-41.64 -86.148)"
        />
        <path
          d="M160.152,102.006c0,3.406,4.694,4.89,7.545,5.931,1.525.562,2.728,1.08,2.728,1.762,0,.923-2.127,1.244-5.5,1.244a14.731,14.731,0,0,1-6.26-1.643l-1.044,4.811c.8.475,4.536,1.559,10.072,1.559,7.747,0,11.837-2.564,12.841-7.134a4.455,4.455,0,0,0,.079-.72c0-3.126-5.094-4.731-7.824-5.774-1.485-.562-2.81-1.08-2.81-1.762,0-.88,1.446-1.362,5.418-1.362a12.545,12.545,0,0,1,5.938,1.525l1.006-4.693a26.038,26.038,0,0,0-9.675-1.561C165.369,94.19,160.152,97.314,160.152,102.006Z"
          data-name="Path 22"
          fill="#fff"
          id="Path_22"
          transform="translate(-31.452 -86.148)"
        />
        <path
          d="M182.995,110.571a3.892,3.892,0,0,1,.12-.924l1.768-8.3h5.456l1.042-4.978h-5.415l1-4.815h-9.511l-4.172,19.621a6.407,6.407,0,0,0-.159,1.444c0,3.13,2.687,4.776,8.144,4.776a53.148,53.148,0,0,0,6.222-.483l1.041-4.975a14.961,14.961,0,0,1-2.93.563C183.759,112.5,182.995,111.856,182.995,110.571Z"
          data-name="Path 23"
          fill="#fff"
          id="Path_23"
          transform="translate(-21.529 -87.841)"
        />
        <path
          d="M204.436,94.551a30.6,30.6,0,0,0-4.011-.361,54.2,54.2,0,0,0-12.68,1.682l-4.134,19.317h9.509l3.251-15.306a20.313,20.313,0,0,1,5.057-.561c.6.12,1.521.157,1.927.277Z"
          data-name="Path 24"
          fill="#fff"
          id="Path_24"
          transform="translate(-14.819 -86.148)"
        />
        <path
          d="M208.649,89.348H199.1l-1.163,5.419h9.549Z"
          data-name="Path 25"
          fill="#fff"
          id="Path_25"
          transform="translate(-5.668 -89.253)"
        />
        <path
          d="M199.277,94.483,194.9,115h9.513l4.373-20.518Z"
          data-name="Path 26"
          fill="#fff"
          id="Path_26"
          transform="translate(-7.587 -85.96)"
        />
        <path
          d="M219.533,94.19a47.035,47.035,0,0,0-12.516,1.682l-4.093,19.317h9.509l3.291-15.47a7.88,7.88,0,0,1,2.529-.48c1.121,0,1.686.682,1.686,2.085a9.693,9.693,0,0,1-.241,1.961l-2.528,11.9h9.513l3.045-14.265a6.715,6.715,0,0,0,.121-1.244C229.848,96.152,226.2,94.19,219.533,94.19Z"
          data-name="Path 27"
          fill="#fff"
          id="Path_27"
          transform="translate(-2.47 -86.148)"
        />
      </g>
    </svg>
  );
}
