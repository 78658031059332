exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-baza-wiedzy-[slug]-js": () => import("./../../../src/pages/baza-wiedzy/[slug].js" /* webpackChunkName: "component---src-pages-baza-wiedzy-[slug]-js" */),
  "component---src-pages-baza-wiedzy-js": () => import("./../../../src/pages/baza-wiedzy.js" /* webpackChunkName: "component---src-pages-baza-wiedzy-js" */),
  "component---src-pages-dla-profesjonalistow-js": () => import("./../../../src/pages/dla-profesjonalistow.js" /* webpackChunkName: "component---src-pages-dla-profesjonalistow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-produkty-[slug]-js": () => import("./../../../src/pages/produkty/[slug].js" /* webpackChunkName: "component---src-pages-produkty-[slug]-js" */),
  "component---src-pages-produkty-js": () => import("./../../../src/pages/produkty.js" /* webpackChunkName: "component---src-pages-produkty-js" */),
  "component---src-pages-produkty-leki-dostepne-bez-recepty-js": () => import("./../../../src/pages/produkty-leki-dostepne-bez-recepty.js" /* webpackChunkName: "component---src-pages-produkty-leki-dostepne-bez-recepty-js" */),
  "component---src-pages-produkty-suplementy-diety-js": () => import("./../../../src/pages/produkty-suplementy-diety.js" /* webpackChunkName: "component---src-pages-produkty-suplementy-diety-js" */),
  "component---src-pages-produkty-wyroby-medyczne-js": () => import("./../../../src/pages/produkty-wyroby-medyczne.js" /* webpackChunkName: "component---src-pages-produkty-wyroby-medyczne-js" */)
}

